import React from 'react'
import PropTypes from 'prop-types'
import { DDISelectField } from 'ddiForm/wrapped'
import MobileErrorMessage from 'mobile/components/MobileErrorMessage'
import { DROP_DOWN } from 'app/mobile/constants'
import { useTouchedFields } from 'mobile/pages/OrderPad/utils'

export const CustomDropdownField = ({ dropDownItems, editedValue, description, required, ...props }) => {
  const { handleFocus, touchedField } = useTouchedFields();
  const validationFlag = required && !editedValue && touchedField;
  if (props.value) {
    props = { ...props, initialValue: props.value }
  }
  return (
  <>
    <div style={{marginRight: validationFlag ? 20:0}}>
      <DDISelectField 
      {...props} 
      values={dropDownItems}
      onFocus={()=>{handleFocus(editedValue)}}
      />
    </div>
    { validationFlag ? <MobileErrorMessage fieldType={DROP_DOWN} title={`${description} is required`}/>: null }
  </>
  )
}

CustomDropdownField.propTypes = {
  propertyName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dropDownItems: PropTypes.array,
  editedValue: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool
}

CustomDropdownField.defaultProps = {
  dropDownItems: []
}

export default CustomDropdownField
