import { openScreen } from 'pages/Main/actions'
import BaseTooltipTemplate from 'components/BaseTooltipTemplate'
import memoize from 'memoize-one'
import { plainDeepEqual } from 'utils'
import behaviors from './behaviors'

// import keyToActionMap from './keyToActionMap'
import { getHiddenValue, getTooltip } from './actions'
import createSearch from '../createSearch'

const typesMap = {
  arterms: {
    name: 'A/R Terms',
    route: 'arTermsMaster',
    title: 'A/R Terms Master'
  },

  branch: {
    name: 'Branch',
    route: 'branchMaster',
    title: 'Branch Master',
    groupNames: ['general']
  },
  buyline: {
    name: 'Buy Line',
    route: 'buyline',
    title: 'Product Buy Line'
  },
  consignmentvendor: {
    name: 'Vendor Master',
    route: 'vendorMaster'
  },
  contacts: {
    name: 'Contacts',
    route: 'contactMaster',
    title: 'Contact Master'
  },
  custcategory: {
    name: 'Customer Category',
    route: 'customerCategoryMaster',
    title: 'Customer Category Master'
  },
  customer: {
    name: 'Customer',
    route: 'customerMaster',
    title: 'Customer Master',
    groupNames: ['setup', 'main']
  },
  cyclegroup: {
    name: 'Cycle Count Group',
    route: 'cyclecountgroup',
    title: 'Cycle Count Group Master'
  },
  division: {
    name: 'Division',
    route: 'division'
  },
  dotcode: {
    name: 'DOT Hazardous Materials',
    route: 'dothazardousmaster',
    title: 'DOT Hazardous Materials Master'
  },
  misccharge: {
    name: 'Miscellaneous Charge',
    route: 'miscCharge'
  },
  ordertype: {
    name: 'Order Type',
    route: 'orderTypeMaster',
    title: 'Order Type Master'
  },
  pricegroup: {
    name: 'Price Group',
    route: 'pricegroup',
    title: 'Price Group Master'
  },
  product: {
    name: 'Product',
    route: 'productMaster',
    title: 'Product Master',
    groupNames: ['setup', 'main']
  },
  productline: {
    name: 'Product Line',
    route: 'productLineMaster',
    title: 'Product Line'
  },
  producttaxgroup: {
    name: 'Product Tax Group',
    route: 'producttaxgroup',
    title: 'Product Tax Group Master'
  },
  salesman: {
    name: 'Salesman',
    route: 'salesmanMaster',
    title: 'Salesman Master'
  },
  shipto: {
    name: 'Ship To',
    route: 'shipToMaster',
    title: 'Ship To Master'
  },
  shipvia: {
    name: 'Ship-via',
    route: 'shipViaMaster',
    title: 'Ship-via Master'
  },
  tax: {
    name: 'Customer Tax',
    route: 'customerTaxMaster',
    title: 'Customer Tax'
  },
  taxgroup: {
    name: 'Product Tax Group',
    route: 'producttaxgroup',
    title: 'Product Tax Group Master'
  },
  territory: {
    name: 'Customer Territory'
  },
  vendor: {
    name: 'Vendor',
    route: 'vendorMaster',
    title: 'Vendor Master',
    groupNames: ['setup', 'main']
  },
  warehouse: {
    name: 'Warehouse',
    route: 'warehouseMaster',
    title: 'Warehouse Master',
    groupNames: ['general']
  },
  whsebuyzone: {
    image: 'company_warehouse_buy_zone_16.png',
    route: 'warehousebuyzone',
    name: 'Warehouse Buy Zone',
    title: 'Warehouse Buy Zone Master'
  },
  writer: {
    name: 'User',
    route: 'userMaster',
    title: 'User Master'
  }
  // company: {i li
  //   name: 'Company',
  //   route: 'Co'
  // }
}

const getRecordNameArgs = memoize(props => {
  const recordName = props.recordName || props.value
  const shipToFields = ['shipToId', 'shipTo', 'defaultShipToId']
  const orderedByFields = ['orderedById']

  if (props?.propertyName && shipToFields.includes(props.propertyName)) {
    return props.parentId ? `${props.parentId}!${recordName}` : recordName
  }

  if (props?.propertyName && orderedByFields.includes(props.propertyName)) {
    return props.parentId ? `C|${props.parentId}|${recordName}` : recordName
  }

  return recordName
}, plainDeepEqual)

const IndexSearch = createSearch({
  name: 'IndexSearch',
  type: 'indexSearch',
  behaviors,
  popoverStyle: { width: 700 },
  customContextMenuItems() {
    // debugger
    // console.log(this)
    if (!this) return []
    const searchType = String(
      this.props.indexSearchType || this.props.meta.searchType
    ).toLowerCase()

    const record = typesMap[searchType]
    if (this.props.value && !record && searchType) {
      // debugger
    }
    return !record
      ? []
      : [
          {
            text: `Open ${record.name} Master`,
            icon: 'open_in_new',
            iconStyle: {
              color: '#444',
              fontSize: 16,
              verticalAlign: 'middle'
            },
            onClick: () => {
              if (this?.props?.customScreenOpenAction) {
                this.props.dispatch(this.props.customScreenOpenAction)
              } else {
                const openScreenParams =
                  record?.groupNames && Array.isArray(record.groupNames)
                    ? {
                        name: record.route,
                        title: record.title || record.name,
                        dataId: this.props.elementDataId ? this.props.elementDataId : this.props.value,
                        groupNames: record.groupNames
                      }
                    : {
                        name: record.route,
                        title: record.title || record.name,
                        dataId: this.props.value
                      }
                // debugger

                if (
                  this?.props?.displayDescription &&
                  this?.props?.form &&
                  this?.props?.getFormState
                ) {
                  /* need to get the actual value when the description is showing, e.g. in a loaded SOE record -- SVE 3/15/2022 */
                  this.props
                    .dispatch(
                      getHiddenValue(this.props.form, {
                        propertyName: this.props.propertyName
                      })
                    )
                    .then(result => {
                      const { value } = result
                      this.props.dispatch(
                        openScreen({
                          ...openScreenParams,
                          dataId: value
                        })
                      )
                    })
                } else {
                  this.props.dispatch(openScreen(openScreenParams))
                }
              }
            },
            enabled: !!this.props.value
          }
        ]

    // return []
  },
  tooltip: {
    component: BaseTooltipTemplate,
    // keyToActionMap,

    networkRequestPromise: getTooltip,
    style: { width: 400 },
    requestArgs() {
      // console.log('requestArgs', this.props)
      return {
        indexSearchType:
          this.props.indexSearchType ||
          (this.props.meta && this.props.meta.searchType),
        propertyName: this.props.propertyName,
        recordName: this.props.agGridReact
          ? this.props.data.recordName
          : getRecordNameArgs(this.props)

        // recordName: this.props.agGridReact
        //   ? this.props.data.recordName
        //   : this.props.recordName || this.props.value
      }
    }
  }
})
// const DDIIndexSearch = props => <Field component={IndexSearch} {...props} />

export default IndexSearch
