import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DialogContent, FormControl, Select, MenuItem } from '@material-ui/core'
import { connect } from 'react-redux'
import { setPOSRegisterNum } from '../../actions'

const mapState = state => ({
  defaultRegister: state.get('auth').get('defaultRegister')
})

class Register extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    defaultRegister: PropTypes.any.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.state = { value:(this.props.data.numOfRegisters< this.props.defaultRegister?-1:this.props.defaultRegister) || 1 }
  }

  componentDidMount() {
    this.props.dispatch(setPOSRegisterNum(this.state.value))
  }

  handleChange = event => {
    const {
      target: { value }
    } = event
    this.setState({ value }, () =>
      this.props.dispatch(setPOSRegisterNum(value))
    )
  }

  render() {
    let items = []
    for (let i = 1; i <= this.props.data.numOfRegisters; i++) {
      items = items.concat(
        <MenuItem value={i} key={i}>
          {i.toString()}
        </MenuItem>
      )
    }
    return (
      <div>
        <DialogContent style={{ zIndex: 3000 }}>
          <FormControl style={{ zIndex: 999999 }}>
            <Select
              value={this.state.value}
              onChange={this.handleChange}
              className="mui-select-field"
              style={{ zIndex: 999999 }}
            >
              {items}
            </Select>
          </FormControl>
        </DialogContent>
      </div>
    )
  }
}

export default connect(mapState)(Register)
