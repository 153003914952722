import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DDITextField } from 'ddiForm/wrapped'
import MobileErrorMessage from 'mobile/components/MobileErrorMessage'
import { useTouchedFields } from 'mobile/pages/OrderPad/utils'

export const CustomTextField = React.memo(({ fieldType, value, editedValue, description, required,  ...props }) => {
  const [isMultiline, setIsMultiline] = useState(false)
  const { handleFocus, touchedField } = useTouchedFields();
  const validationFlag = required && !editedValue && touchedField;

  useEffect(() => {
    setIsMultiline(fieldType === 'TXTN')
  }, [fieldType])

  return (
    <div style={{marginRight: validationFlag ? 20:0}} className="custom-fields-wrapper">
      <DDITextField
        {...props}
        multiline={isMultiline}
        rows={isMultiline ? 5 : null}
        mask={fieldType === 'MD' ? 'numeric' : null}
        initialValue={value}
        variant={isMultiline ? 'outlined' : 'standard'}
        preventFocusCallback={false}
        onFocus={()=>{handleFocus(editedValue)}}
        />
      {validationFlag ? <MobileErrorMessage title={`${description} is required`}/>: null}
    </div>
  )
});

CustomTextField.propTypes = {
  fieldType: PropTypes.string.isRequired,
  value: PropTypes.string,
  editedValue: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool
}

CustomTextField.defaultProps = {
  value: ''
}

export default CustomTextField
