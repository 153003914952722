import { createAsyncRequestTypes } from 'utils'

export const SALES_ORDER = '@@SALES_ORDER@@'

export const SET_SEARCH_AREA_EXPANSION_PANEL_STATE = `${SALES_ORDER}SET_SEARCH_AREA_EXPANSION_PANEL_STATE`
export const FAKE_IS_EDITING_MODE = `${SALES_ORDER}FAKE_IS_EDITING_MODE`

export const GET_PRODUCT_DETAILS = createAsyncRequestTypes(
  `${SALES_ORDER}GET_PRODUCT_DETAILS`
)

export const CLOSE_SALES_ORDER = createAsyncRequestTypes(
  `${SALES_ORDER}CLOSE_SALES_ORDER`
)

export const GET_CAYAN_KEY = createAsyncRequestTypes(
  `${SALES_ORDER}GET_CAYAN_KEY`
)

export const SET_PRODUCT_DATA_DEFAULTS = `${SALES_ORDER}SET_PRODUCT_DATA_DEFAULTS`

export const CHANGE_BRANCH = createAsyncRequestTypes(
  `${SALES_ORDER}/CHANGE_BRANCH`
)

export const LAUNCH_CUSTOMER_SHIP_TO = `${SALES_ORDER}LAUNCH_CUSTOMER_SHIP_TO`

export const CLEAR_ORDER = `${SALES_ORDER}CLEAR_ORDER`
export const ON_PROPERTY_CHANGE = createAsyncRequestTypes(
  `${SALES_ORDER}/ON_PROPERTY_CHANGE`
)

export const HANDLE_MODAL_INTERACTION = `${SALES_ORDER}HANDLE_MODAL_INTERACTION`
export const UPDATE_VALIDATION_MESSAGES = `${SALES_ORDER}UPDATE_VALIDATION_MESSAGES`
export const SET_INTERNAL_NOTES = `${SALES_ORDER}SET_INTERNAL_NOTES`
export const UNSET_INTERNAL_NOTES = `${SALES_ORDER}UNSET_INTERNAL_NOTES`
export const SET_INTERNAL_NOTES_VIEWED = `${SALES_ORDER}SET_INTERNAL_NOTES_VIEWED`


export const UPDATE_FIELD_AND_DESCRIPTION = `${SALES_ORDER}UPDATE_FIELD_AND_DESCRIPTION`

export const SET_SELECTED_LINE_ITEM = `${SALES_ORDER}SET_SELECTED_LINE_ITEM`
export const SET_SELECTED_LINE_ITEM_ID = `${SALES_ORDER}SET_SELECTED_LINE_ITEM_ID`
export const PROPERTY_REFRESH = createAsyncRequestTypes(
  `${SALES_ORDER}/PROPERTY_REFRESH`
)

export const REMOVE_LINE_ITEM = createAsyncRequestTypes(
  `${SALES_ORDER}/REMOVE_LINE_ITEM`
)

export const SET_WAREHOUSE = createAsyncRequestTypes(
  `${SALES_ORDER}/SET_WAREHOUSE`
)

export const UPDATE_LINE_ITEM = createAsyncRequestTypes(
  `${SALES_ORDER}/UPDATE_LINE_ITEM`
)

export const SUPPRESS_BOX_QUANTITY_PROMPT = `${SALES_ORDER}SUPPRESS_BOX_QUANTITY_PROMPT`
export const SET_SALES_ORDER_INITIALIZED = `${SALES_ORDER}SET_SALES_ORDER_INITIALIZED`

export const GET_SDS = createAsyncRequestTypes(`${SALES_ORDER}GET_SDS`)
export const GET_DOC_SPEC = createAsyncRequestTypes(
  `${SALES_ORDER}GET_DOC_SPEC`
)

export const SAVE_SALES_ORDER = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_SALES_ORDER`
)

export const EDIT_SALES_ORDER = createAsyncRequestTypes(
  `${SALES_ORDER}EDIT_SALES_ORDER`
)

export const VALIDATE_SALES_ORDER = createAsyncRequestTypes(
  `${SALES_ORDER}VALIDATE_SALES_ORDER`
)

export const REMOVE_GRID_ITEM = createAsyncRequestTypes(
  `${SALES_ORDER}/REMOVE_GRID_ITEM`
)

export const UPDATE_GRID_ITEM = createAsyncRequestTypes(
  `${SALES_ORDER}/UPDATE_GRID_ITEM`
)

export const OPEN_COMMENTS = createAsyncRequestTypes(
  `${SALES_ORDER}/OPEN_COMMENTS`
)

export const SET_COMMENT = createAsyncRequestTypes(`${SALES_ORDER}/SET_COMMENT`)

export const DELETE_COMMENT = createAsyncRequestTypes(
  `${SALES_ORDER}/DELETE_COMMENT`
)

export const INIT_PRINT_ROUTINE = createAsyncRequestTypes(
  `${SALES_ORDER}/INIT_PRINT_ROUTINE`
)

export const VALIDATE_PRINT_DOCUMENT = createAsyncRequestTypes(
  `${SALES_ORDER}/VALIDATE_PRINT_DOCUMENT`
)

export const CREATE_NEW = createAsyncRequestTypes(`${SALES_ORDER}/CREATE_NEW`)

export const LOCK_SALES_ORDER_NOTES = createAsyncRequestTypes(
  `${SALES_ORDER}/LOCK_SALES_ORDER_NOTES`
)

export const SAVE_SALES_ORDER_NOTES = createAsyncRequestTypes(
  `${SALES_ORDER}/SAVE_SALES_ORDER_NOTES`
)

export const DELETE_SALES_ORDER_NOTES = createAsyncRequestTypes(
  `${SALES_ORDER}/DELETE_SALES_ORDER_NOTES`
)

export const CANCEL_SALES_ORDER_NOTES_EDIT = createAsyncRequestTypes(
  `${SALES_ORDER}/CANCEL_SALES_ORDER_NOTES_EDIT`
)

export const CANCEL_SALES_ORDER_EDIT = createAsyncRequestTypes(
  `${SALES_ORDER}/CANCEL_SALES_ORDER_EDIT`
)

export const CANCEL_NEW_SALES_ORDER_EDIT = createAsyncRequestTypes(
  `${SALES_ORDER}/CANCEL_NEW_SALES_ORDER_EDIT`
)

export const SEND_SALES_ORDER_DOCUMENT = createAsyncRequestTypes(
  `${SALES_ORDER}/SEND_SALES_ORDER_DOCUMENT`
)

export const STORE_UI_FEATURE_STATE = `${SALES_ORDER}STORE_UI_FEATURE_STATE`

export const ADD_OPTIONS = createAsyncRequestTypes(`${SALES_ORDER}/ADD_OPTIONS`)

export const CLEAR_REBATE = createAsyncRequestTypes(
  `${SALES_ORDER}CLEAR_REBATE`
)

export const READ_LINE_ITEM = createAsyncRequestTypes(
  `${SALES_ORDER}READ_LINE_ITEM`
)

export const ADD_EDITABLE_GRID_ROW = `${SALES_ORDER}ADD_EDITABLE_GRID_ROW`

export const CONFIRM_PROVISIONAL_CHANGES = createAsyncRequestTypes(
  `${SALES_ORDER}CONFIRM_PROVISIONAL_CHANGES`
)

export const CANCEL_PROVISIONAL_CHANGES = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_PROVISIONAL_CHANGES`
)

export const CHANGE_PROVISIONAL_LINE_ITEM = createAsyncRequestTypes(
  `${SALES_ORDER}CHANGE_PROVISIONAL_LINE_ITEM`
)

export const REMOVE_BOX_QUANTITY_FLAG = `${SALES_ORDER}REMOVE_BOX_QUANTITY_FLAG`

export const VOID_SALES_ORDER = createAsyncRequestTypes(
  `${SALES_ORDER}VOID_SALES_ORDER`
)

export const LAUNCH_COPY_ORDER = `${SALES_ORDER}LAUNCH_COPY_ORDER`

export const COPY_ORDER = createAsyncRequestTypes(`${SALES_ORDER}COPY_ORDER`)

export const CHANGE_COPY_ORDER_OPTION = createAsyncRequestTypes(
  `${SALES_ORDER}CHANGE_COPY_ORDER_OPTION`
)

export const EXECUTE_COPY_ORDER = createAsyncRequestTypes(
  `${SALES_ORDER}EXECUTE_COPY_ORDER`
)

export const COPY_PRODUCTS = createAsyncRequestTypes(
  `${SALES_ORDER}COPY_PRODUCTS`
)

export const CANCEL_COPY_PRODUCTS = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_COPY_PRODUCTS`
)

export const CONVERT_QUOTE_TO_ORDER = createAsyncRequestTypes(
  `${SALES_ORDER}CONVERT_QUOTE_TO_ORDER`
)

export const CLEAR_SHIPMENT_STATUS = createAsyncRequestTypes(
  `${SALES_ORDER}CLEAR_SHIPMENT_STATUS`
)

export const CREATE_FAST_PRODUCT = createAsyncRequestTypes(
  `${SALES_ORDER}CREATE_FAST_PRODUCT`
)

export const LAUNCH_FAST_PRODUCT_MODAL = `${SALES_ORDER}LAUNCH_FAST_PRODUCT_MODAL`

export const GET_FAST_PRODUCT_META = createAsyncRequestTypes(
  `${SALES_ORDER}GET_FAST_PRODUCT_META`
)

export const GET_FAST_PRODUCT_DEFAULTS = createAsyncRequestTypes(
  `${SALES_ORDER}GET_FAST_PRODUCT_DEFAULTS`
)

export const SAVE_FAST_PRODUCT = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_FAST_PRODUCT`
)

export const CANCEL_FAST_PRODUCT_ENTRY = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_FAST_PRODUCT_ENTRY`
)

export const FAST_PRODUCT_PROPERTY_CHANGE = createAsyncRequestTypes(
  `${SALES_ORDER}FAST_PRODUCT_PROPERTY_CHANGE`
)

export const VALIDATE_FAST_PRODUCT = createAsyncRequestTypes(
  `${SALES_ORDER}VALIDATE_FAST_PRODUCT`
)

export const SAVE_MANUAL_SHIP_TO = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_MANUAL_SHIP_TO`
)

export const LAUNCH_MANUAL_SHIP_TO = createAsyncRequestTypes(
  `${SALES_ORDER}LAUNCH_MANUAL_SHIP_TO`
)

export const LAUNCH_FAST_PRODUCT_SAVE_INTERFACE = `${SALES_ORDER}LAUNCH_FAST_PRODUCT_SAVE_INTERFACE`

export const SET_FIELD_ERROR_MESSAGE = `${SALES_ORDER}SET_FIELD_ERROR_MESSAGE`

export const CLEAR_INVALID_FAST_PRODUCT_ID = `${SALES_ORDER}CLEAR_INVALID_FAST_PRODUCT_ID`

export const GET_FAST_PRODUCT_COPY_FROM_META = createAsyncRequestTypes(
  `${SALES_ORDER}GET_FAST_PRODUCT_COPY_FROM_META`
)

export const GET_FAST_PRODUCT_COPY_FROM_DESCRIPTIONS = createAsyncRequestTypes(
  `${SALES_ORDER}GET_FAST_PRODUCT_COPY_FROM_DESCRIPTIONS`
)

export const FAST_PRODUCT_COPY_FROM_EXECUTE = createAsyncRequestTypes(
  `${SALES_ORDER}FAST_PRODUCT_COPY_FROM_EXECUTE`
)

export const FAST_PRODUCT_COPY_FROM_CANCEL = createAsyncRequestTypes(
  `${SALES_ORDER}FAST_PRODUCT_COPY_FROM_CANCEL`
)

export const LAUNCH_FAST_CUSTOMER = createAsyncRequestTypes(
  `${SALES_ORDER}LAUNCH_FAST_CUSTOMER`
)

export const FAST_CUSTOMER_PROPERTY_CHANGE = createAsyncRequestTypes(
  `${SALES_ORDER}FAST_CUSTOMER_PROPERTY_CHANGE`
)

export const EXIT_FAST_CUSTOMER = createAsyncRequestTypes(
  `${SALES_ORDER}EXIT_FAST_CUSTOMER`
)

export const SAVE_FAST_CUSTOMER = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_FAST_CUSTOMER`
)

export const RELEASE_CREDIT_HOLD = createAsyncRequestTypes(
  `${SALES_ORDER}RELEASE_CREDIT_HOLD`
)

export const INITIALIZE_DEFAULT_TO_QUOTE = `${SALES_ORDER}INITIALIZE_DEFAULT_TO_QUOTE`

export const PRODUCT_SUBSTITUTE = createAsyncRequestTypes(
  `${SALES_ORDER}PRODUCT_SUBSTITUTE`
)

export const LAUNCH_ITEM_GROUPS_MODAL = createAsyncRequestTypes(
  `${SALES_ORDER}LAUNCH_ITEM_GROUPS_MODAL`
)

export const CANCEL_ITEM_GROUPS = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_ITEM_GROUPS`
)

export const SEND_LINKED_SALES_ORDER_DOCUMENT = createAsyncRequestTypes(
  `${SALES_ORDER}SEND_LINKED_SALES_ORDER_DOCUMENT`
)

export const CHANGE_ITEM_GROUPS = createAsyncRequestTypes(
  `${SALES_ORDER}CHANGE_ITEM_GROUPS`
)

export const SAVE_ITEM_GROUPS = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_ITEM_GROUPS`
)

export const DELETE_ITEM_GROUP = createAsyncRequestTypes(
  `${SALES_ORDER}DELETE_ITEM_GROUP`
)

export const ITEM_GROUPS_PROPERTY_CHANGE = createAsyncRequestTypes(
  `${SALES_ORDER}ITEM_GROUPS_PROPERTY_CHANGE`
)

export const LAUNCH_ADD_ITEM_GROUP_MODAL = createAsyncRequestTypes(
  `${SALES_ORDER}LAUNCH_ADD_ITEM_GROUP_MODAL`
)

export const OPEN_PREVIOUS_ORDER = `${SALES_ORDER}OPEN_PREVIOUS_ORDER`
export const CLEAR_LINE_ITEMS_GRID = `${SALES_ORDER}CLEAR_LINE_ITEMS_GRID`

export const TRIGGER_UPDATE_LINE_ITEM = `${SALES_ORDER}TRIGGER_UPDATE_LINE_ITEM`
export const TRIGGER_PROPERTY_CHANGE_PROCESS = `${SALES_ORDER}TRIGGER_PROPERTY_CHANGE_PROCESS`

export const MOVE_PRODUCT_TO_DIFFERENT_GROUP = createAsyncRequestTypes(
  `${SALES_ORDER}MOVE_PRODUCT_TO_DIFFERENT_GROUP`
)

export const LINE_ITEMS_BULK_ADD = createAsyncRequestTypes(
  `${SALES_ORDER}LINE_ITEMS_BULK_ADD`
)

export const NOTIFY_AUTOMATED_BOX_QUANTITY_ROUTINE_COMPLETED = `${SALES_ORDER}NOTIFY_AUTOMATED_BOX_QUANTITY_ROUTINE_COMPLETED`
export const NOTIFY_AUTOMATED_PRODUCT_NOTES_ROUTINE_COMPLETED = `${SALES_ORDER}NOTIFY_AUTOMATED_PRODUCT_NOTES_ROUTINE_COMPLETED`

export const SET_SELECTED_AVAILABLE_PRODUCT = `${SALES_ORDER}SET_SELECTED_AVAILABLE_PRODUCT`

export const OPEN_CUSTOMER_ORDER_PAD_IN_MODAL = `${SALES_ORDER}OPEN_CUSTOMER_ORDER_PAD_IN_MODAL`

export const CLEAR_MANUAL_DATA_ID = `${SALES_ORDER}CLEAR_MANUAL_DATA_ID`

export const INITIALIZE_PAYMENT = createAsyncRequestTypes(
  `${SALES_ORDER}INITIALIZE_PAYMENT`
)

export const VOID_PAYMENT = createAsyncRequestTypes(
  `${SALES_ORDER}VOID_PAYMENT`
)

export const GET_RETURN_MODAL_DATA = `${SALES_ORDER}GET_RETURN_MODAL_DATA`
export const CLEAR_RETURN_MODAL_DATA = `${SALES_ORDER}CLEAR_RETURN_MODAL_DATA`

export const OVERPAYMENT_RESPONSE = createAsyncRequestTypes(
  `${SALES_ORDER}OVERPAYMENT_REPSPONSE`
)

export const MODAL_ROUTINE_COMPLETED = `${SALES_ORDER}MODAL_ROUTINE_COMPLETED`

export const TRIGGER_RETURN_PRODUCT_VALIDATION = `${SALES_ORDER}TRIGGER_RETURN_PRODUCT_VALIDATION`

export const NOTIFY_AUTOMATED_RETURN_PROCESS_COMPLETED = `${SALES_ORDER}NOTIFY_AUTOMATED_RETURN_PROCESS_COMPLETED`

export const RETURN_AUTH_MODAL_CLOSED = `${SALES_ORDER}RETURN_AUTH_MODAL_CLOSED`

export const STORE_RETURN_MODAL_DATA = `${SALES_ORDER}STORE_RETURN_MODAL_DATA`
export const SAVE_SALES_ORDER_CANCEL = `${SALES_ORDER}SAVE_SALES_ORDER_CANCEL`

export const LAUNCH_DEPOSIT_INVOICE_ENTRY_MODAL = `${SALES_ORDER}LAUNCH_DEPOSIT_INVOICE_ENTRY_MODAL`

export const DEPOSIT_INVOICE_ENTRY = createAsyncRequestTypes(
  `${SALES_ORDER}DEPOSIT_INVOICE_ENTRY`
)

export const EDIT_SHIP_TO = createAsyncRequestTypes(
  `${SALES_ORDER}EDIT_SHIP_TO`
)

export const CREATE_INVOICE = createAsyncRequestTypes(
  `${SALES_ORDER}CREATE_INVOICE`
)

export const DELETE_INVOICE = createAsyncRequestTypes(
  `${SALES_ORDER}DELETE_INVOICE`
)

export const NOTIFY_CREDIT_CARD_ERROR = `${SALES_ORDER}NOTIFY_CREDIT_CARD_ERROR`

export const CLEAR_PAYMENT_TRANSACTION_DATA = `${SALES_ORDER}CLEAR_PAYMENT_TRANSACTION_DATA`
export const SET_CONTACT_REFERENCE_FIELD = `${SALES_ORDER}SET_CONTACT_REFERENCE_FIELD`

export const BILL_FOR_BACKORDER = createAsyncRequestTypes(
  `${SALES_ORDER}BILL_FOR_BACKORDER`
)

export const GET_VAULT_CARDS = createAsyncRequestTypes(
  `${SALES_ORDER}GET_VAULT_CARDS`
)

export const REMOVE_VAULT_CARD = createAsyncRequestTypes(
  `${SALES_ORDER}REMOVE_VAULT_CARD`
)

export const CLEAR_FAST_CONTACT = `${SALES_ORDER}CLEAR_FAST_CONTACT`

export const SELECT_LINKED_REFUND = createAsyncRequestTypes(
  `${SALES_ORDER}SELECT_LINKED_REFUND`
)

export const LINKED_REFUND_PROCESS = createAsyncRequestTypes(
  `${SALES_ORDER}LINKED_REFUND_PROCESS`
)

export const LINKED_REFUND_RESPONSE = `${SALES_ORDER}LINKED_REFUND_RESPONSE`

export const SET_DEFAULT_PAYMENT_CONTACT = `${SALES_ORDER}SET_DEFAULT_PAYMENT_CONTACT`

export const READ_CARD_TERMINAL_STATUS = createAsyncRequestTypes(
  `${SALES_ORDER}READ_CARD_TERMINAL_STATUS`
)

export const CANCEL_CARD_READER_PAYMENT = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_CARD_READER_PAYMENT`
)

export const CARD_PROCESSOR_INITIALIZED = `${SALES_ORDER}CARD_PROCESSOR_INITIALIZED`

export const REVERT_DATA_ID = `${SALES_ORDER}REVERT_DATA_ID`

export const REMOVE_ADDITIONAL_DATA_MAP_ROW = `${SALES_ORDER}REMOVE_ADDITIONAL_DATA_MAP_ROW`

export const SAVE_REPAIR_ITEM_DATA = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_REPAIR_ITEM_DATA`
)

export const LAUNCH_REPAIR_ITEM_MODAL_INTERFACE = `${SALES_ORDER}LAUNCH_REPAIR_ITEM_MODAL_INTERFACE`

export const LOCK_PRODUCT_NOTE = createAsyncRequestTypes(
  `${SALES_ORDER}LOCK_PRODUCT_NOTE`
)

export const UNLOCK_PRODUCT_NOTE = createAsyncRequestTypes(
  `${SALES_ORDER}UNLOCK_PRODUCT_NOTE`
)

export const SAVE_PRODUCT_NOTE = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_PRODUCT_NOTE`
)

export const LAUNCH_CSV_UPLOAD_INTERFACE = `${SALES_ORDER}LAUNCH_CSV_UPLOAD_INTERFACE`

export const ADD_BULK_LINE_ITEMS = `${SALES_ORDER}ADD_BULK_LINE_ITEMS`

export const ACCEPT_DUPLICATE_PRODUCT = `${SALES_ORDER}ACCEPT_DUPLICATE_PRODUCT`
export const REJECT_DUPLICATE_PRODUCT = `${SALES_ORDER}REJECT_DUPLICATE_PRODUCT`

export const LAUNCH_CUSTOMER_PART_NUMBER_MODAL = createAsyncRequestTypes(
  `${SALES_ORDER}LAUNCH_CUSTOMER_PART_NUMBER_MODAL`
)

export const CANCEL_EDIT_PART_NUMBER = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_EDIT_PART_NUMBER`
)

export const DELETE_PART_NUMBER = createAsyncRequestTypes(
  `${SALES_ORDER}DELETE_PART_NUMBER`
)

export const SAVE_PART_NUMBER = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_PART_NUMBER`
)

export const ADD_ITEM_GROUP_CELL = createAsyncRequestTypes(
  `${SALES_ORDER}ADD_ITEM_GROUP_CELL`
)

export const INITIALIZE_MULTI_SELECT = createAsyncRequestTypes(
  `${SALES_ORDER}INITIALIZE_MULTI_SELECT`
)

export const OPEN_PROMISE_DATE_MODAL = createAsyncRequestTypes(
  `${SALES_ORDER}OPEN_PROMISE_DATE_MODAL`
)

export const SET_PROMISE_DATE = createAsyncRequestTypes(
  `${SALES_ORDER}SET_PROMISE_DATE`
)

export const SET_SELECTED_MULTI_RETAIN_TAXABLE = `${SALES_ORDER}SET_SELECTED_MULTI_RETAIN_TAXABLE`

export const LAUNCH_RECALCULATE_PRICES_MODAL = createAsyncRequestTypes(
  `${SALES_ORDER}LAUNCH_RECALCULATE_PRICES_MODAL`
)

export const RECALCULATE_COSTS = createAsyncRequestTypes(
  `${SALES_ORDER}RECALCULATE_COSTS`
)
export const CANCEL_PRICE_OVERRIDE = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_PRICE_OVERRIDE`
)
export const PRICE_OVERRIDE = createAsyncRequestTypes(
  `${SALES_ORDER}PRICE_OVERRIDE`
)
export const LAUNCH_RECALCULATE_COSTS_MODAL = `${SALES_ORDER}LAUNCH_RECALCULATE_COSTS_MODAL`

export const HANDLE_MULTIPLE_LINE_ITEM_UPDATES = createAsyncRequestTypes(
  `${SALES_ORDER}HANDLE_MULTIPLE_LINE_ITEM_UPDATES`
)

export const CLEAR_COPY_ORDER_DATA = `${SALES_ORDER}CLEAR_COPY_ORDER_DATA`

export const HANDLE_OPEN_TRANSFERS_COMMENT_EDITOR = `${SALES_ORDER}HANDLE_OPEN_TRANSFERS_COMMENT_EDITOR`

export const HANDLE_PROCUREMENT_GRID_UPDATE = createAsyncRequestTypes(
  `${SALES_ORDER}HANDLE_PROCUREMENT_GRID_UPDATE`
)

export const ADD_PROCUREMENT_GRID_ROW = `${SALES_ORDER}ADD_PROCUREMENT_GRID_ROW`

export const CLEAN_UP_PROCUREMENT_GRID_ROW = `${SALES_ORDER}CLEAN_UP_PROCUREMENT_GRID_ROW`

export const FLAG_NEWLY_ADDED_LINE_NUMBER = `${SALES_ORDER}FLAG_NEWLY_ADDED_LINE_NUMBER`

export const INITIALIZE_FORM_TITLE = `${SALES_ORDER}INITIALIZE_FORM_TITLE`

export const INITIATE_CARD_TERMINAL_SIGNATURE = createAsyncRequestTypes(
  `${SALES_ORDER}INITIATE_CARD_TERMINAL_SIGNATURE`
)

export const POLL_CARD_TERMINAL_SIGNATURE = createAsyncRequestTypes(
  `${SALES_ORDER}POLL_CARD_TERMINAL_SIGNATURE`
)

export const SAVE_LAYOUT_FOR_USER_AUTHENTICATION = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_LAYOUT_FOR_USER_AUTHENTICATION`
)

export const ON_LINE_ITEMS_GRID_READY = `${SALES_ORDER}ON_LINE_ITEMS_GRID_READY`

export const REPEAT_ALL_RECALCULATE = createAsyncRequestTypes(
  `${SALES_ORDER}REPEAT_ALL_RECALCULATE`
)

export const CLEAR_ALL_RECALCULATE = createAsyncRequestTypes(
  `${SALES_ORDER}CLEAR_ALL_RECALCULATE`
)

export const RECALCULATE_PRICES = createAsyncRequestTypes(
  `${SALES_ORDER}RECALCULATE_PRICES`
)

export const CLOSE_RECALCULATE_PRICES = createAsyncRequestTypes(
  `${SALES_ORDER}CLOSE_RECALCULATE_PRICES`
)

export const CHANGE_RECALCULATE_PRICES = createAsyncRequestTypes(
  `${SALES_ORDER}CHANGE_RECALCULATE_PRICES`
)

export const SET_SELECTED_SEQUENCE_NUMBER = `${SALES_ORDER}SET_SELECTED_SEQUENCE_NUMBER`

export const RECALCULATE_PRICES_GRID_TAB_ATTEMPT = `${SALES_ORDER}RECALCULATE_PRICES_GRID_TAB_ATTEMPT`

export const CREDIT_INVOICE = createAsyncRequestTypes(
  `${SALES_ORDER}CREDIT_INVOICE`
)

export const FLAG_ACTIVE_GRID_LAYOUT = `${SALES_ORDER}FLAG_ACTIVE_GRID_LAYOUT`

export const CANCEL_EDIT_AFTER_CLEAR = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_EDIT_AFTER_CLEAR`
)

export const CHANGE_FAST_PRODUCT_VENDOR = createAsyncRequestTypes(
  `${SALES_ORDER}CHANGE_FAST_PRODUCT_VENDOR`
)

export const GET_WAREHOUSE_INVENTORY = createAsyncRequestTypes(
  `${SALES_ORDER}GET_WAREHOUSE_INVENTORY`
)

export const OPEN_INVOICE_HISTORY_MODAL = createAsyncRequestTypes(
  `${SALES_ORDER}OPEN_INVOICE_HISTORY_MODAL`
)

export const FETCH_INVOICE_HISTORY = createAsyncRequestTypes(
  `${SALES_ORDER}FETCH_INVOICE_HISTORY`
)

export const OPEN_SPAWNED_ORDERS_MODAL = createAsyncRequestTypes(
  `${SALES_ORDER}OPEN_SPAWNED_ORDERS_MODAL`
)

export const OPEN_CUT_LENGTH_MODAL = `${SALES_ORDER}OPEN_CUT_LENGTH_MODAL`

export const CALCULATE_CUSTOM_CUT = createAsyncRequestTypes(
  `${SALES_ORDER}CALCULATE_CUSTOM_CUT`
)

export const GET_SOE_SALESMEN_COMMISSIONS = createAsyncRequestTypes(
  `${SALES_ORDER}GET_SOE_SALESMEN_COMMISSIONS`
)

export const TRIGGER_SHIPPING_GRID_BULK_CHANGE = `${SALES_ORDER}TRIGGER_SHIPPING_GRID_BULK_CHANGE`

export const TRY_IMPORT_AUTO_QUOTE = `${SALES_ORDER}TRY_IMPORT_AUTO_QUOTE`

export const MODIFY_LINE_ITEM_SERIAL_NUMBER = createAsyncRequestTypes(
  `${SALES_ORDER}MODIFY_LINE_ITEM_SERIAL_NUMBER`
)

export const HANDLE_SERIAL_NUMBER_REVIEW_CHANGE = createAsyncRequestTypes(
  `${SALES_ORDER}HANDLE_SERIAL_NUMBER_REVIEW_CHANGE`
)

export const DESTROY_SERIAL_NUMBER_FIELDS = `${SALES_ORDER}DESTROY_SERIAL_NUMBER_FIELDS`

export const HANDLE_BACKORDER_COMMITMENT_SCHEDULE = createAsyncRequestTypes(
  `${SALES_ORDER}HANDLE_BACKORDER_COMMITMENT_SCHEDULE`
)

export const DESTROY_VIRTUAL_FIELDS = `${SALES_ORDER}DESTROY_VIRTUAL_FIELDS`

export const HANDLE_REBATE_INFO_INTERACTION = createAsyncRequestTypes(
  `${SALES_ORDER}HANDLE_REBATE_INFO_INTERACTION`
)

export const HANDLE_RECURRING_ORDER_INTERACTION = createAsyncRequestTypes(
  `${SALES_ORDER}HANDLE_RECURRING_ORDER_INTERACTION`
)

export const HANDLE_SWAP_COMMITTED_QUANTITIES_INTERACTION = createAsyncRequestTypes(
  `${SALES_ORDER}HANDLE_SWAP_COMMITTED_QUANTITIES_INTERACTION`
)

export const EXIT_SCREEN_FROM_MODAL = `${SALES_ORDER}EXIT_SCREEN_FROM_MODAL`

export const ADD_COMPONENTS_ROW = `${SALES_ORDER}ADD_COMPONENTS_ROW`

export const OPEN_REBATE_COST_TAB = createAsyncRequestTypes(
  `${SALES_ORDER}OPEN_REBATE_COST_TAB`
)

export const CREATE_CUSTOM_ASSEMBLY = createAsyncRequestTypes(
  `${SALES_ORDER}CREATE_CUSTOM_ASSEMBLY`
)

export const CLEAR_COMPONENTS_ROW = `${SALES_ORDER}CLEAR_COMPONENTS_ROW`

export const HANDLE_SHIPPER_HQ_INTERACTION = createAsyncRequestTypes(
  `${SALES_ORDER}HANDLE_SHIPPER_HQ_INTERACTION`
)
