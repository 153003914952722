import React from 'react'
import BarcodeScanner from 'components/BarcodeScanner'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { Icon, IconButton } from '@material-ui/core'
import { once } from 'lodash'

export default React.memo(props => {
  const { height, width } = useWindowDimensions()
  // const [data, setData] = React.useState('no data')
  const scanSuccess = once(text => {
    // setData(text)
    props.onUpdate(text)
    // setData(text)
  })

  return (
    <div
      style={{
        position: 'fixed',
        background: 'black',
        top: 0,
        zIndex: 99999,
        marginLeft: -20,
        height,
        width
      }}
    >
      {/*<div style={{ background: 'red', color: 'white' }}>{data}</div> */}
      <IconButton
        // className="hvr-bounce-in"
        // style={navigationArrowStyles.style}
        onClick={props.closeScan}
        tabIndex={-1}
        // onDoubleClick={noop}
      >
        <Icon
          tabIndex={-1}
          style={{ padding: 0, fontSize: 16, color: 'white' }}
        >
          close
        </Icon>
      </IconButton>
      <BarcodeScanner
        width={width}
        height={height - 40}
        // onUpdate={onUpdate}
        scanSuccess={scanSuccess}
        tryHarder
        {...props}
      />
    </div>
  )
})
