import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const INDEX_SEARCH = '@@IndexSearch/'
export const POPULATE_FIELD = `${INDEX_SEARCH}POPULATE_FIELD`
export const BLUR = `${INDEX_SEARCH}BLUR`
export const FOCUS = `${INDEX_SEARCH}FOCUS`
export const CLOSE_SEARCH = `${INDEX_SEARCH}CLOSE_SEARCH`
export const EXACT_MATCH_SEARCH = createAsyncRequestTypes(
  `${INDEX_SEARCH}EXACT_MATCH_SEARCH`
)
export const PARTIAL_MATCH_SEARCH = createAsyncRequestTypes(
  `${INDEX_SEARCH}PARTIAL_MATCH_SEARCH`
)
export const FIND_NEXT = createAsyncRequestTypes(`${INDEX_SEARCH}FIND_NEXT`)
export const FIND_PREV = createAsyncRequestTypes(`${INDEX_SEARCH}FIND_PREV`)
export const TOGGLE_FILTER = `${INDEX_SEARCH}TOGGLE_FILTER`
export const GET_TOOLTIP = createAsyncRequestTypes(`${INDEX_SEARCH}GET_TOOLTIP`)
export const GET_SEARCH_FILTERS = createAsyncRequestTypes(
  `${INDEX_SEARCH}GET_SEARCH_FILTERS`
)

export const INDEX_SEARCH_TYPES = {
  AP_TERMS: 'apTerms',
  AR_AGING_LETTERS: 'arAgingLetters',
  AR_TERMS: 'arTerms',
  BANK: 'bank',
  BINS: 'bins',
  BRANCH: 'branch',
  BUY_LINE: 'buyLine',
  CONTACTS: 'contacts',
  CONTAINER: 'container',
  CURRENCY_CODE: 'currencyCode',
  CUSTOMER: 'customer',
  CUSTOMER_SOURCE: 'customerSource',
  CUSTOM_CUBE: 'customCube',
  CUST_CATEGORY: 'custCategory',
  CYCLE_GROUP: 'cycleGroup',
  DIVISION: 'division',
  DOT_CODE: 'dotCode',
  ECOMMERCE_BRANCH: 'eCommerceBranch',
  EVENT_TYPE: 'eventType',
  FINANCIAL_REPORT: 'financialReport',
  GL_ACCOUNT: 'glAccount',
  GL_GROUP: 'glGroup',
  INVOICE_ORDER: 'invoiceOrder',
  JOB: 'job',
  LOT: 'lot',
  MAJOR_BUY_LINE: 'majorBuyLine',
  MAJOR_GROUP: 'majorGroup',
  MARGIN_CAP: 'marginCap',
  MISC_CHARGE: 'miscCharge',
  MISC_CHARGE_PROGRAM: 'miscChargeProgram',
  NONE: 'none',
  NON_VENDOR: 'nonVendor',
  OPTIONS_AND_ACCESSORIES_GROUPS: 'optionsAndAccessoriesGroups',
  ORDER_TYPE: 'orderType',
  PICKER_PACKER: 'pickerPacker',
  PRICE_CONTRACT: 'priceContract',
  PRICE_GROUP: 'priceGroup',
  PRINTERS: 'printers',
  PRODUCT: 'product',
  PRODUCT_LABELS: 'productLabels',
  PRODUCT_LINE: 'productLine',
  PRODUCT_TAX_GROUP: 'productTaxGroup',
  PURCHASE_ORDER: 'purchaseOrder',
  PURCHASE_ORDER_SPECIAL_INSTRUCTIONS: 'purchaseOrderSpecialInstructions',
  REASON_INV_ADJ: 'reasonInvAdj',
  REPORT_WRITER: 'reportWriter',
  SALESMAN: 'salesman',
  SALES_ORDER: 'salesOrder',
  SALES_ORDER_REPORT: 'salesOrderReport',
  SALES_ORDER_SPECIAL_INSTRUCTIONS: 'salesOrderSpecialInstructions',
  SHIP_TO: 'shipTo',
  SHIP_VIA: 'shipVia', // shipVia
  SYSTEM_USERS: 'systemUsers',
  TAX: 'tax',
  TERRITORY: 'territory',
  TRUCK: 'truck',
  UOM: 'uom',
  VENDOR: 'vendor',
  VENDOR_CATEGORY: 'vendorCategory',
  VENDOR_SOURCE: 'vendorSource',
  WAREHOUSE: 'warehouse',
  WARRANTY_FAIL_CODE: 'warrantyFailCode',
  WEB_CAT: 'webCat',
  WHSE_BUY_ZONE: 'whseBuyZone'
}

export const ORDER_SEARCH_URL = `${BASE_INFORM_API_URL}/search/salesordernumber` // dup with lowere one..
export const PARTIAL_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/indexsearch/partialmatch`
export const EXACT_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/indexsearch/exactmatch`
export const FIND_NEXT_SEARCH_URL = `${BASE_INFORM_API_URL}/search/findnext`
export const FIND_PREV_SEARCH_URL = `${BASE_INFORM_API_URL}/search/findprevious`
export const GET_TOOLTIP_URL = `${BASE_INFORM_API_URL}/search/indexsearch/summary`
export const GET_SEARCH_FILTER_URL = `${BASE_INFORM_API_URL}/search/indexsearch/filters`

export const OPEN_FILTERS_GRID = `${INDEX_SEARCH}OPEN_FILTERS_GRID`
export const CLOSE_FILTERS_GRID = `${INDEX_SEARCH}CLOSE_FILTERS_GRID`
export const CLEAR_FILTER_FIELD = `${INDEX_SEARCH}CLEAR_FILTER_FIELD`
export const SET_TEXT_FILTER_FIELD = `${INDEX_SEARCH}SET_TEXT_FILTER_FIELD`
export const RESET_FILTERS = `${INDEX_SEARCH}RESET_FILTERS`
export const CLEAR_SEARCH = `${INDEX_SEARCH}CLEAR_SEARCH`
export const FOUND_INVALID_VALUES = `${INDEX_SEARCH}FOUND_INVALID_VALUES`
export const FOUND_INVALID_VALUES_REJECTED = `${INDEX_SEARCH}FOUND_INVALID_VALUES_REJECTED`
export const CLEAR_FORM = `${INDEX_SEARCH}CLEAR_FORM`

export const GET_HIDDEN_VALUE = `${INDEX_SEARCH}GET_HIDDEN_VALUE`

export const calculateWidth = (field,rowData ) => {
  const padding = 20; // Add some padding to the calculated width
  const maxLength = rowData.reduce((max, row) => Math.max(max, row[field].length), 0);
  return maxLength * 8 + padding; // Approximate width calculation
}
