import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import ConnectedField from './ConnectedField'
import { withDDIForm } from './DDIFormContext'

class Field extends PureComponent {
  ref = React.createRef()

  static propTypes = {
    disableRegister: PropTypes.bool,
    enablePropChange: PropTypes.bool,
    id: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    required: PropTypes.bool,
    searchType: PropTypes.string,
    type: PropTypes.string,
    ConnectedField: PropTypes.any,
    noRequiredIcon: PropTypes.bool
  }

  static defaultProps = {
    disableRegister: false,
    id: null,
    searchType: null,
    type: null,
    autoFocus: false,
    enablePropChange: false,
    ConnectedField,
    noRequiredIcon: true,
    required: false,
  }

  constructor(...args) {
    super(...args)
    this.id = this.props.id || uuid.v1()
  }

  componentDidMount() {
    const {
      disableRegister,
      propertyName,
      component: { ddiType },
      initialValue,
      meta,
      leader,
      validate,
      registerField,
      index,
      valueKey,
      parentArray
    } = this.props

    if (!disableRegister && propertyName && registerField) {
      registerField({
        propertyName,
        searchType: this.props.searchType,
        ddiType,
        meta,
        leader,
        initialValue,
        validate,
        type: this.props.type,
        index,
        valueKey,
        parentArray
      })
    }
  }

  render() {
    const { propertyName } = this.props
    return (
      <this.props.ConnectedField
        {...this.props}
        propertyName={propertyName}
        id={this.id}
        ref={this.ref}
        noRequiredIcon={this.props.noRequiredIcon}
      />
    )
  }
}

export default withDDIForm(Field)
