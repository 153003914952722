import React from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { parseNumber, plainDeepEqual } from 'utils'
import NumberSpinner from 'ddiForm/NumberSpinner'
import { updateLineItemQuantity } from 'pages/CustomerOrderPad/actions'

const GridCellNumberSpinner = ({ data }) => {
  const { quantity, recordId } = data
  const dispatch = useDispatch()

  const onNumberSpinnerChange = debounce(newValue => {
    // console.log('onNumberSpinnerChange', newValue, props)
    dispatch(
      updateLineItemQuantity('mobileOrderPad', {
        recordId,
        quantity: parseNumber(newValue),
        showErrorModal: false
      })
    )
  }, 1000)

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <NumberSpinner
        // uniqueKey={uniqueKey}
        value={quantity}
        isField={false}
        step={1.0}
        updateValueCallback={onNumberSpinnerChange}
        inputProps={{
          noDecimalLimit: true
        }}
      />
    </div>
  )
}

export default React.memo(GridCellNumberSpinner, plainDeepEqual)
