import React,{useState} from 'react'
import { Icon,ClickAwayListener,Tooltip as SimpleToolTip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {DROP_DOWN, ORDER_PAD} from 'app/mobile/constants'

const MobileErrorMessage = React.memo(({title, fieldType}) => {
  const[show,setShow] = useState(false);
  const Tooltip = withStyles(theme => ({
    tooltip: {
      margin: theme.spacing(1)
    },
  }))(SimpleToolTip);

  return (
     <ClickAwayListener onClickAway={()=>setShow(false)}>
      <div role="presentation">
      <Tooltip
            title={title}
            open={show}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-50, 0],
                    },
                  },
                ],
              },
            }}
            leaveDelay={2000}
          >
            {fieldType === ORDER_PAD ? 
            <Icon style={{ color: '#d9534f', fontSize: 16, cursor: 'pointer', marginBottom: 5, marginLeft:5, marginRight: '1%', float:'right' }} 
              onClick={() => {setShow(true)}}>
                error_outline
            </Icon> 
            : 
            fieldType === DROP_DOWN?  
            <Icon style={{ color: '#d9534f', fontSize: 16, cursor: 'pointer',  marginLeft:5, marginTop: -15, float:'right' }} 
            onClick={() => {setShow(true)}}
            >
              error_outline
            </Icon>
            :
            <Icon style={{ color: '#d9534f', fontSize: 16, cursor: 'pointer', marginTop: -30, marginRight: -20, float:'right' }} 
            onClick={() => {setShow(true)}}
            >
              error_outline
            </Icon>
          }
      </Tooltip>
      </div>
    </ClickAwayListener>
  )
})

export default MobileErrorMessage
