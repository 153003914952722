import React, { Component } from 'react'
import { Icon, TextField, Tooltip as SimpleToolTip } from '@material-ui/core'
import invariant from 'invariant'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

export default class RegexCellEditor extends Component {
  static propTypes = { 
    onChange: PropTypes.func.isRequired, 
    field: PropTypes.string.isRequired, 
    node: PropTypes.object.isRequired, 
    value: PropTypes.string,
    regex: PropTypes.shape({
      test: PropTypes.func.isRequired
    }).isRequired
  }

  constructor(...args) {
    super(...args)
    invariant(this.props.regex, 'must provide a regex!')

    const value = this.props.value || ''
    this.state = {
      open: false,
      value,
      valid: this.props.regex.test(value),
      errorMessage: false
    }
    this.domNodeRef = React.createRef();
    console.log(this.props)
  }

  componentWillUnmount() {
    if (this.state.isDirty && this.state.valid && this.props.onChange) {
      this.props.onChange(this.props.field, this.state.value, this.props.node)
    }
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
 }

 handleClickOutside = event => {
  const domNode = this.domNodeRef.current;

  if (!domNode?.contains(event.target)) {
    this.setState({
        errorMessage: false
      })
  }
}

  onChange = e => {
    const { value } = e.target
    this.setState({ value, valid: this.props.regex.test(value), isDirty: true })
  }

  onClick = () => this.setState({ open: !this.state.open })

  onClose = () => this.setState({ open: false })
  setErrorMessage = () => this.setState({ errorMessage: true})
  handleTooltipClose = () => this.setState({ errorMessage: false})
  onRowClicked = e =>
    this.setState({
      value: e.data.description,
      open: false
    })

  bindInputRef = c => (this.input = c)

  input = null

  refresh = params => {
    return false
  }

  getValue = () => this.state.value
  render() {
    const Tooltip = withStyles(theme => ({
      tooltip: {
        margin: theme.spacing(1)
      },
    }))(SimpleToolTip)
    return (
      <div style={{ width: '100%', height: 27, position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: 27
          }}
        >
          <TextField
            value={this.state.value}
            onChange={this.onChange}
            InputProps={{
              style: { height: 26, padding: 0 },
              endAdornment: !this.state.valid ? (
                <Tooltip
                title={
                  !this.state.value
                    ? 'Email is required'
                    : 'Not a valid email address format (e.g. example@somewhere.com)'
                }
                open={this.state.errorMessage}
                onClose={this.handleTooltipClose}
              >
                <Icon style={{ color: '#d9534f', fontSize: 16, cursor: 'pointer' }} 
                  onClick={this.setErrorMessage} 
                  onMouseEnter={this.setErrorMessage} 
                  onMouseLeave={this.handleTooltipClose}
                  ref={this.domNodeRef}>
                  error_outline
                </Icon>
                </Tooltip>
              ) : (
                undefined
              )
            }}
            style={{ height: 26, padding: 0 }}
            inputProps={{
              style: { height: 26, padding: 0 }
            }}
          />
        </div>
      </div>
    )
  }
}
