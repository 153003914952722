/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const setSearchAreaExpansionPanelState = (form, { expanded }) =>
  createAction(
    CONSTANTS.SET_SEARCH_AREA_EXPANSION_PANEL_STATE,
    { expanded },
    form
  )

export const clearOrder = (form, retainDataId = false) =>
  createAction(CONSTANTS.CLEAR_ORDER, { retainDataId }, form)

export const getProductDetails = createAsyncFormAction(
  CONSTANTS.GET_PRODUCT_DETAILS
)

export const closeSalesOrder = createAsyncFormAction(
  CONSTANTS.CLOSE_SALES_ORDER
)

export const setProductDataDefaults = (form, { propertyName }) =>
  createAction(CONSTANTS.SET_PRODUCT_DATA_DEFAULTS, { propertyName }, form)

export const changeBranch = createAsyncFormAction(CONSTANTS.CHANGE_BRANCH)

export const launchCustomerShipTo = form =>
  createAction(CONSTANTS.LAUNCH_CUSTOMER_SHIP_TO, {}, form)

export const onPropertyChange = createAsyncFormAction(
  CONSTANTS.ON_PROPERTY_CHANGE
)

export const handleModalInteraction = (
  form,
  { guid, value, cancelledChanges }
) =>
  createAction(
    CONSTANTS.HANDLE_MODAL_INTERACTION,
    { guid, value, cancelledChanges },
    form
  )

export const updateValidationMessages = (form, { propertyName }) =>
  createAction(CONSTANTS.UPDATE_VALIDATION_MESSAGES, { propertyName }, form)

export const setInternalNotes = (form, { record }) =>
  createAction(CONSTANTS.SET_INTERNAL_NOTES, { record }, form)

export const unsetInternalNotes = form =>
  createAction(CONSTANTS.UNSET_INTERNAL_NOTES, {}, form)

export const setInternalNotesViewed = (form, { record }) =>
  createAction(CONSTANTS.SET_INTERNAL_NOTES_VIEWED, { record }, form)

export const updateFieldAndDescription = (
  form,
  { propertyName, value, descriptionField, description }
) =>
  createAction(
    CONSTANTS.UPDATE_FIELD_AND_DESCRIPTION,
    { propertyName, value, descriptionField, description },
    form
  )

export const propertyRefresh = createAsyncFormAction(CONSTANTS.PROPERTY_REFRESH)

export const removeLineItem = createAsyncFormAction(CONSTANTS.REMOVE_LINE_ITEM)

export const setWarehouse = createAsyncFormAction(CONSTANTS.SET_WAREHOUSE)
export const updateLineItem = createAsyncFormAction(CONSTANTS.UPDATE_LINE_ITEM)

export const setSalesOrderInitialized = form =>
  createAction(CONSTANTS.SET_SALES_ORDER_INITIALIZED, {}, form)

export const supressBoxQuantityPrompt = (form, { rowId }) =>
  createAction(CONSTANTS.SUPPRESS_BOX_QUANTITY_PROMPT, { rowId }, form)

export const getSDS = createAsyncFormAction(CONSTANTS.GET_SDS)
export const getDocSpec = createAsyncFormAction(CONSTANTS.GET_DOC_SPEC)

export const saveSalesOrder = createAsyncFormAction(CONSTANTS.SAVE_SALES_ORDER)
export const editSalesOrder = createAsyncFormAction(CONSTANTS.EDIT_SALES_ORDER)

export const validateSalesOrder = createAsyncFormAction(
  CONSTANTS.VALIDATE_SALES_ORDER
)

export const removeGridItem = createAsyncFormAction(CONSTANTS.REMOVE_GRID_ITEM)
export const updateGridItem = createAsyncFormAction(CONSTANTS.UPDATE_GRID_ITEM)

export const openComments = createAsyncFormAction(CONSTANTS.OPEN_COMMENTS)
export const setComment = createAsyncFormAction(CONSTANTS.SET_COMMENT)
export const deleteComment = createAsyncFormAction(CONSTANTS.DELETE_COMMENT)

export const initPrintRoutine = createAsyncFormAction(
  CONSTANTS.INIT_PRINT_ROUTINE
)

export const validatePrintDocument = createAsyncFormAction(
  CONSTANTS.VALIDATE_PRINT_DOCUMENT
)
export const createNewSalesOrder = createAsyncFormAction(CONSTANTS.CREATE_NEW)

export const lockSalesOrderNotes = createAsyncFormAction(
  CONSTANTS.LOCK_SALES_ORDER_NOTES
)
export const saveSalesOrderNotes = createAsyncFormAction(
  CONSTANTS.SAVE_SALES_ORDER_NOTES
)
export const cancelSalesOrderNotesEdit = createAsyncFormAction(
  CONSTANTS.CANCEL_SALES_ORDER_NOTES_EDIT
)

export const deleteSalesOrderNotes = createAsyncFormAction(
  CONSTANTS.DELETE_SALES_ORDER_NOTES
)

export const cancelSalesOrderEdit = createAsyncFormAction(
  CONSTANTS.CANCEL_SALES_ORDER_EDIT
)

export const cancelNewSalesOrderEdit = createAsyncFormAction(
  CONSTANTS.CANCEL_NEW_SALES_ORDER_EDIT
)

export const sendSalesOrderDocument = createAsyncFormAction(
  CONSTANTS.SEND_SALES_ORDER_DOCUMENT
)

export const storeUIFeatureState = (form, { feature, featureState }) =>
  createAction(
    CONSTANTS.STORE_UI_FEATURE_STATE,
    { feature, featureState },
    form
  )

export const addOptions = createAsyncFormAction(CONSTANTS.ADD_OPTIONS)

export const clearRebate = createAsyncFormAction(CONSTANTS.CLEAR_REBATE)

export const readLineItem = createAsyncFormAction(CONSTANTS.READ_LINE_ITEM)

export const addEditableGridRow = (form, { propertyName }) =>
  createAction(CONSTANTS.ADD_EDITABLE_GRID_ROW, { propertyName }, form)

export const confirmProvisionalChanges = createAsyncFormAction(
  CONSTANTS.CONFIRM_PROVISIONAL_CHANGES
)

export const cancelProvisionalChanges = createAsyncFormAction(
  CONSTANTS.CANCEL_PROVISIONAL_CHANGES
)

export const changeProvisionalLineItem = createAsyncFormAction(
  CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM
)

export const removeBoxQuantityFlag = (form, { lineNumber }) =>
  createAction(CONSTANTS.REMOVE_BOX_QUANTITY_FLAG, { lineNumber }, form)

export const voidSalesOrder = createAsyncFormAction(CONSTANTS.VOID_SALES_ORDER)

export const launchCopyOrder = form =>
  createAction(CONSTANTS.LAUNCH_COPY_ORDER, {}, form)

export const copyOrder = createAsyncFormAction(CONSTANTS.COPY_ORDER)

export const changeCopyOrderOption = createAsyncFormAction(
  CONSTANTS.CHANGE_COPY_ORDER_OPTION
)

export const executeCopyOrder = createAsyncFormAction(
  CONSTANTS.EXECUTE_COPY_ORDER
)

export const copyProducts = createAsyncFormAction(CONSTANTS.COPY_PRODUCTS)

export const cancelCopyProducts = createAsyncFormAction(
  CONSTANTS.CANCEL_COPY_PRODUCTS
)

export const convertQuoteToOrder = createAsyncFormAction(
  CONSTANTS.CONVERT_QUOTE_TO_ORDER
)

export const clearShipmentStatus = createAsyncFormAction(
  CONSTANTS.CLEAR_SHIPMENT_STATUS
)

export const createFastProduct = createAsyncFormAction(
  CONSTANTS.CREATE_FAST_PRODUCT
)

export const launchFastProductModal = form =>
  createAction(CONSTANTS.LAUNCH_FAST_PRODUCT_MODAL, {}, form)

export const saveManualShipTo = createAsyncFormAction(
  CONSTANTS.SAVE_MANUAL_SHIP_TO
)

export const getCayanKey = createAsyncFormAction(CONSTANTS.GET_CAYAN_KEY)

export const getFastProductMeta = createAsyncFormAction(
  CONSTANTS.GET_FAST_PRODUCT_META
)

export const getFastProductDefaults = createAsyncFormAction(
  CONSTANTS.GET_FAST_PRODUCT_DEFAULTS
)

export const saveFastProduct = createAsyncFormAction(
  CONSTANTS.SAVE_FAST_PRODUCT
)

export const cancelFastProductEntry = createAsyncFormAction(
  CONSTANTS.CANCEL_FAST_PRODUCT_ENTRY
)

export const fastProductPropertyChange = createAsyncFormAction(
  CONSTANTS.FAST_PRODUCT_PROPERTY_CHANGE
)

export const validateFastProduct = createAsyncFormAction(
  CONSTANTS.VALIDATE_FAST_PRODUCT
)

export const launchFastProductSaveInterface = (
  form,
  { cb, dispatch, gridApi }
) =>
  createAction(
    CONSTANTS.LAUNCH_FAST_PRODUCT_SAVE_INTERFACE,
    { cb, dispatch, gridApi },
    form
  )

export const setFieldErrorMessage = (form, { propertyName, errorMessage }) =>
  createAction(
    CONSTANTS.SET_FIELD_ERROR_MESSAGE,
    { propertyName, errorMessage },
    form
  )

export const launchManualShipTo = createAsyncFormAction(
  CONSTANTS.LAUNCH_MANUAL_SHIP_TO
)

export const clearInvalidFastProductId = form =>
  createAction(CONSTANTS.CLEAR_INVALID_FAST_PRODUCT_ID, {}, form)

export const getFastProductCopyFromMeta = createAsyncFormAction(
  CONSTANTS.GET_FAST_PRODUCT_COPY_FROM_META
)

export const getFastProductCopyFromDescriptions = createAsyncFormAction(
  CONSTANTS.GET_FAST_PRODUCT_COPY_FROM_DESCRIPTIONS
)

export const fastProductCopyFromExecute = createAsyncFormAction(
  CONSTANTS.FAST_PRODUCT_COPY_FROM_EXECUTE
)

export const fastProductCopyFromCancel = createAsyncFormAction(
  CONSTANTS.FAST_PRODUCT_COPY_FROM_CANCEL
)
export const launchFastCustomer = createAsyncFormAction(
  CONSTANTS.LAUNCH_FAST_CUSTOMER
)

export const fastCustomerPropertyChange = createAsyncFormAction(
  CONSTANTS.FAST_CUSTOMER_PROPERTY_CHANGE
)

export const exitFastCustomer = createAsyncFormAction(
  CONSTANTS.EXIT_FAST_CUSTOMER
)

export const saveFastCustomer = createAsyncFormAction(
  CONSTANTS.SAVE_FAST_CUSTOMER
)

export const releaseCreditHold = createAsyncFormAction(
  CONSTANTS.RELEASE_CREDIT_HOLD
)

export const initializeDefaultToQuote = (form, { value }) =>
  createAction(CONSTANTS.INITIALIZE_DEFAULT_TO_QUOTE, { value }, form)

export const productSubstitute = createAsyncFormAction(
  CONSTANTS.PRODUCT_SUBSTITUTE
)

export const launchItemGroupsModal = createAsyncFormAction(
  CONSTANTS.LAUNCH_ITEM_GROUPS_MODAL
)

export const cancelItemGroups = createAsyncFormAction(
  CONSTANTS.CANCEL_ITEM_GROUPS
)

export const changeItemGroups = createAsyncFormAction(
  CONSTANTS.CHANGE_ITEM_GROUPS
)

export const saveItemGroups = createAsyncFormAction(CONSTANTS.SAVE_ITEM_GROUPS)

export const deleteItemGroup = createAsyncFormAction(
  CONSTANTS.DELETE_ITEM_GROUP
)

export const itemGroupsPropertyChange = createAsyncFormAction(
  CONSTANTS.ITEM_GROUPS_PROPERTY_CHANGE
)

export const sendLinkedSalesOrderDocument = createAsyncFormAction(
  CONSTANTS.SEND_LINKED_SALES_ORDER_DOCUMENT
)

export const launchAddItemGroupModal = createAsyncFormAction(
  CONSTANTS.LAUNCH_ADD_ITEM_GROUP_MODAL
)

export const openPreviousOrder = (form, { dataId }) =>
  createAction(CONSTANTS.OPEN_PREVIOUS_ORDER, { dataId }, form)

export const clearLineItemsGrid = form =>
  createAction(CONSTANTS.CLEAR_LINE_ITEMS_GRID, {}, form)

export const triggerUpdateLineItem = (
  form,
  { propertyName, data, value, colDef = {}, ...rest }
) =>
  createAction(
    CONSTANTS.TRIGGER_UPDATE_LINE_ITEM,
    {
      propertyName,
      data,
      value,
      colDef,
      ...rest
    },
    form
  )

export const triggerPropertyChangeProcess = (form, { propertyName, value }) =>
  createAction(
    CONSTANTS.TRIGGER_PROPERTY_CHANGE_PROCESS,
    {
      propertyName,
      value
    },
    form
  )

export const moveProductToDifferentGroup = createAsyncFormAction(
  CONSTANTS.MOVE_PRODUCT_TO_DIFFERENT_GROUP
)

export const lineItemsBulkAdd = createAsyncFormAction(
  CONSTANTS.LINE_ITEMS_BULK_ADD
)

export const notifyAutomatedBoxQuantityRoutineCompleted = form =>
  createAction(
    CONSTANTS.NOTIFY_AUTOMATED_BOX_QUANTITY_ROUTINE_COMPLETED,
    {},
    form
  )

export const notifyAutomatedProductNotesRoutineCompleted = form =>
  createAction(
    CONSTANTS.NOTIFY_AUTOMATED_PRODUCT_NOTES_ROUTINE_COMPLETED,
    {},
    form
  )

export const setSelectedAvailableProduct = (form, data) =>
  createAction(CONSTANTS.SET_SELECTED_AVAILABLE_PRODUCT, { ...data }, form)

export const openCustomerOrderPadInModal = (form, { gridApi }) =>
  createAction(CONSTANTS.OPEN_CUSTOMER_ORDER_PAD_IN_MODAL, { gridApi }, form)

export const clearManualDataId = (form, { isCheckout = false }) =>
  createAction(CONSTANTS.CLEAR_MANUAL_DATA_ID, { isCheckout }, form)

export const initializePayment = createAsyncFormAction(
  CONSTANTS.INITIALIZE_PAYMENT
)

export const voidPayment = createAsyncFormAction(CONSTANTS.VOID_PAYMENT)

export const getReturnModalData = (
  form,
  { rowIndex, gridApi, type, isRequest, launchModal, data = {} }
) =>
  createAction(
    CONSTANTS.GET_RETURN_MODAL_DATA,
    {
      rowIndex,
      gridApi,
      type,
      isRequest,
      launchModal,
      data
    },
    form
  )

export const clearReturnModalData = (form, { lineNumber }) =>
  createAction(CONSTANTS.CLEAR_RETURN_MODAL_DATA, { lineNumber }, form)

export const overpaymentResponse = createAsyncFormAction(
  CONSTANTS.OVERPAYMENT_RESPONSE
)

export const modalRoutineCompleted = form =>
  createAction(CONSTANTS.MODAL_ROUTINE_COMPLETED, {}, form)

export const triggerReturnProductValidation = (form, { gridApi }) =>
  createAction(CONSTANTS.TRIGGER_RETURN_PRODUCT_VALIDATION, { gridApi }, form)

export const notifyAutomatedReturnProcessCompleted = form =>
  createAction(CONSTANTS.NOTIFY_AUTOMATED_RETURN_PROCESS_COMPLETED, {}, form)

export const returnAuthModalClosed = (
  form,
  { rgaType = '', data = {}, isChange = false }
) =>
  createAction(
    CONSTANTS.RETURN_AUTH_MODAL_CLOSED,
    { rgaType, data, isChange },
    form
  )

export const storeReturnModalData = (
  form,
  { lineNumber, selectedInvoiceHistory }
) =>
  createAction(
    CONSTANTS.STORE_RETURN_MODAL_DATA,
    { lineNumber, selectedInvoiceHistory },
    form
  )

export const launchDepositInvoiceEntryModal = form =>
  createAction(CONSTANTS.LAUNCH_DEPOSIT_INVOICE_ENTRY_MODAL, {}, form)

export const depositInvoiceEntry = createAsyncFormAction(
  CONSTANTS.DEPOSIT_INVOICE_ENTRY
)

export const editShipTo = createAsyncFormAction(CONSTANTS.EDIT_SHIP_TO)

export const createInvoice = createAsyncFormAction(CONSTANTS.CREATE_INVOICE)

export const deleteInvoice = createAsyncFormAction(CONSTANTS.DELETE_INVOICE)

export const notifyCreditCardError = (form, { message }) =>
  createAction(CONSTANTS.NOTIFY_CREDIT_CARD_ERROR, { message }, form)

export const clearPaymentTransactionData = form =>
  createAction(CONSTANTS.CLEAR_PAYMENT_TRANSACTION_DATA, {}, form)

export const setContactReferenceField = (form, { propertyName }) =>
  createAction(CONSTANTS.SET_CONTACT_REFERENCE_FIELD, { propertyName }, form)

export const billForBackorder = createAsyncFormAction(
  CONSTANTS.BILL_FOR_BACKORDER
)

export const getVaultCards = createAsyncFormAction(CONSTANTS.GET_VAULT_CARDS)

export const removeVaultCard = createAsyncFormAction(
  CONSTANTS.REMOVE_VAULT_CARD
)

export const clearFastContact = form =>
  createAction(CONSTANTS.CLEAR_FAST_CONTACT, {}, form)

export const selectLinkedRefund = createAsyncFormAction(
  CONSTANTS.SELECT_LINKED_REFUND
)

export const linkedRefundProcess = createAsyncFormAction(
  CONSTANTS.LINKED_REFUND_PROCESS
)

export const linkedRefundResponse = (form, data) =>
  createAction(CONSTANTS.LINKED_REFUND_RESPONSE, { data }, form)

export const setDefaultPaymentContact = (form, { value, description }) =>
  createAction(
    CONSTANTS.SET_DEFAULT_PAYMENT_CONTACT,
    { value, description },
    form
  )

export const readCardTerminalStatus = createAsyncFormAction(
  CONSTANTS.READ_CARD_TERMINAL_STATUS
)

export const cancelCardReaderPayment = createAsyncFormAction(
  CONSTANTS.CANCEL_CARD_READER_PAYMENT
)

export const cardProcessorInitialized = (form, { initialized }) =>
  createAction(CONSTANTS.CARD_PROCESSOR_INITIALIZED, { initialized }, form)

export const revertDataId = (form, { dataId }) =>
  createAction(CONSTANTS.REVERT_DATA_ID, { dataId }, form)

export const removeAdditionalDataMapRow = (form, { rowId }) =>
  createAction(CONSTANTS.REMOVE_ADDITIONAL_DATA_MAP_ROW, { rowId }, form)

export const saveRepairItemData = createAsyncFormAction(
  CONSTANTS.SAVE_REPAIR_ITEM_DATA
)

export const launchRepairItemModalInterface = (
  form,
  { data, rowIndex, gridApi }
) =>
  createAction(
    CONSTANTS.LAUNCH_REPAIR_ITEM_MODAL_INTERFACE,
    { data, rowIndex, gridApi },
    form
  )

export const lockProductNote = createAsyncFormAction(
  CONSTANTS.LOCK_PRODUCT_NOTE
)

export const unlockProductNote = createAsyncFormAction(
  CONSTANTS.UNLOCK_PRODUCT_NOTE
)

export const saveProductNote = createAsyncFormAction(
  CONSTANTS.SAVE_PRODUCT_NOTE
)

export const launchCSVUploadInterface = (form, { gridApi }) =>
  createAction(CONSTANTS.LAUNCH_CSV_UPLOAD_INTERFACE, { gridApi }, form)

export const addBulkLineItems = (
  form,
  { bulkProperties, gridApi, cb, action = 'lineitembulkadd' }
) =>
  createAction(
    CONSTANTS.ADD_BULK_LINE_ITEMS,
    { bulkProperties, gridApi, cb, action },
    form,
    false,
    { thunk: true }
  )

export const acceptDuplicateProduct = (form, { applyForAll, modalId }) =>
  createAction(
    CONSTANTS.ACCEPT_DUPLICATE_PRODUCT,
    { applyForAll, modalId },
    form
  )

export const rejectDuplicateProduct = (form, { applyForAll, modalId }) =>
  createAction(
    CONSTANTS.REJECT_DUPLICATE_PRODUCT,
    { applyForAll, modalId },
    form
  )

export const launchRecalculateCostsModal = (form, payload) =>
  createAction(CONSTANTS.LAUNCH_RECALCULATE_COSTS_MODAL, payload, form)

export const launchCustomerPartNumberModal = createAsyncFormAction(
  CONSTANTS.LAUNCH_CUSTOMER_PART_NUMBER_MODAL
)
export const recalculateCosts = createAsyncFormAction(
  CONSTANTS.RECALCULATE_COSTS
)

export const cancelEditPartNumber = createAsyncFormAction(
  CONSTANTS.CANCEL_EDIT_PART_NUMBER
)

export const deletePartNumber = createAsyncFormAction(
  CONSTANTS.DELETE_PART_NUMBER
)
export const cancelPriceOverride = createAsyncFormAction(
  CONSTANTS.CANCEL_PRICE_OVERRIDE
)

export const priceOverride = createAsyncFormAction(CONSTANTS.PRICE_OVERRIDE)
export const savePartNumber = createAsyncFormAction(CONSTANTS.SAVE_PART_NUMBER)

export const addItemGroupCell = createAsyncFormAction(
  CONSTANTS.ADD_ITEM_GROUP_CELL
)

export const initializeMultiSelect = createAsyncFormAction(
  CONSTANTS.INITIALIZE_MULTI_SELECT
)

export const openPromiseDateModal = createAsyncFormAction(
  CONSTANTS.OPEN_PROMISE_DATE_MODAL
)

export const setPromiseDate = createAsyncFormAction(CONSTANTS.SET_PROMISE_DATE)

export const setSelectedMultiRetainTaxable = (form, lineNumbers) =>
  createAction(
    CONSTANTS.SET_SELECTED_MULTI_RETAIN_TAXABLE,
    { lineNumbers },
    form
  )

export const handleMultipleLineItemUpdates = createAsyncFormAction(
  CONSTANTS.HANDLE_MULTIPLE_LINE_ITEM_UPDATES
)

export const clearCopyOrderData = form =>
  createAction(CONSTANTS.CLEAR_COPY_ORDER_DATA, {}, form)

export const handleOpenTransfersCommentEditor = (
  form,
  { comments, rowIndex }
) =>
  createAction(
    CONSTANTS.HANDLE_OPEN_TRANSFERS_COMMENT_EDITOR,
    { comments, rowIndex },
    form
  )

export const handleProcurementGridUpdate = createAsyncFormAction(
  CONSTANTS.HANDLE_PROCUREMENT_GRID_UPDATE
)

export const addProcurementGridRow = (
  form,
  { gridName, rowKey, emptyGridRow }
) =>
  createAction(
    CONSTANTS.ADD_PROCUREMENT_GRID_ROW,
    { gridName, rowKey, emptyGridRow },
    form
  )

export const cleanUpProcurementGridRow = (
  form,
  { gridName, selectedRowIndex, rowKey }
) =>
  createAction(
    CONSTANTS.CLEAN_UP_PROCUREMENT_GRID_ROW,
    { gridName, selectedRowIndex, rowKey },
    form
  )

export const flagNewlyAddedLineNumber = (form, { lineNumber }) =>
  createAction(CONSTANTS.FLAG_NEWLY_ADDED_LINE_NUMBER, { lineNumber }, form)

export const initializeFormTitle = (form, data) =>
  createAction(CONSTANTS.INITIALIZE_FORM_TITLE, { ...data }, form)

export const initiateCardTerminalSignature = createAsyncFormAction(
  CONSTANTS.INITIATE_CARD_TERMINAL_SIGNATURE
)

export const pollCardTerminalSignature = createAsyncFormAction(
  CONSTANTS.POLL_CARD_TERMINAL_SIGNATURE
)

export const saveLayoutForUserAuthentication = createAsyncFormAction(
  CONSTANTS.SAVE_LAYOUT_FOR_USER_AUTHENTICATION
)

export const onLineItemsGridReady = (form, { gridApi }) =>
  createAction(CONSTANTS.ON_LINE_ITEMS_GRID_READY, { gridApi }, form)

/** ** RECALCULATE PRICES *** */

export const recalculatePrices = createAsyncFormAction(
  CONSTANTS.RECALCULATE_PRICES
)

export const launchRecalculatePricesModal = createAsyncFormAction(
  CONSTANTS.LAUNCH_RECALCULATE_PRICES_MODAL
)

export const repeatAllRecalculate = createAsyncFormAction(
  CONSTANTS.REPEAT_ALL_RECALCULATE
)

export const clearAllRecalculate = createAsyncFormAction(
  CONSTANTS.CLEAR_ALL_RECALCULATE
)

export const closeRecalculatePrices = createAsyncFormAction(
  CONSTANTS.CLOSE_RECALCULATE_PRICES
)

export const changeRecalculatePrices = createAsyncFormAction(
  CONSTANTS.CHANGE_RECALCULATE_PRICES
)

export const setSelectedSequenceNumber = (form, { sequenceNumber }) =>
  createAction(CONSTANTS.SET_SELECTED_SEQUENCE_NUMBER, { sequenceNumber }, form)

/** ********* END RECALCULATE PRICES  ********** */

export const creditInvoice = createAsyncFormAction(CONSTANTS.CREDIT_INVOICE)

export const flagActiveGridLayout = (form, { gridName, action }) =>
  createAction(CONSTANTS.FLAG_ACTIVE_GRID_LAYOUT, { gridName, action }, form)

export const cancelEditAfterClear = createAsyncFormAction(
  CONSTANTS.CANCEL_EDIT_AFTER_CLEAR
)

export const changeFastProductVendor = createAsyncFormAction(
  CONSTANTS.CHANGE_FAST_PRODUCT_VENDOR
)

export const getWarehouseInventory = createAsyncFormAction(
  CONSTANTS.GET_WAREHOUSE_INVENTORY
)

export const openInvoiceHistoryModal = createAsyncFormAction(
  CONSTANTS.OPEN_INVOICE_HISTORY_MODAL
)

export const fetchInvoiceHistory = createAsyncFormAction(
  CONSTANTS.FETCH_INVOICE_HISTORY
)

export const openSpawnedOrdersModal = createAsyncFormAction(
  CONSTANTS.OPEN_SPAWNED_ORDERS_MODAL
)

export const openCutLengthModal = (form, { lineNumber }) =>
  createAction(CONSTANTS.OPEN_CUT_LENGTH_MODAL, { lineNumber }, form)

export const calculateCustomCut = createAsyncFormAction(
  CONSTANTS.CALCULATE_CUSTOM_CUT
)

export const getSOESalesmenCommissions = createAsyncFormAction(
  CONSTANTS.GET_SOE_SALESMEN_COMMISSIONS
)

export const triggerShippingGridBulkChange = (form, { action }) =>
  createAction(CONSTANTS.TRIGGER_SHIPPING_GRID_BULK_CHANGE, { action }, form)

export const tryImportAutoQuote = (form, { content, fileName, gridApi }) =>
  createAction(
    CONSTANTS.TRY_IMPORT_AUTO_QUOTE,
    { content, fileName, gridApi },
    form
  )

export const modifyLineItemSerialNumber = createAsyncFormAction(
  CONSTANTS.MODIFY_LINE_ITEM_SERIAL_NUMBER
)

export const handleSerialNumberReviewChange = createAsyncFormAction(
  CONSTANTS.HANDLE_SERIAL_NUMBER_REVIEW_CHANGE
)

export const destroySerialNumberFields = form =>
  createAction(CONSTANTS.DESTROY_SERIAL_NUMBER_FIELDS, {}, form)

export const handleBackorderCommitmentSchedule = createAsyncFormAction(
  CONSTANTS.HANDLE_BACKORDER_COMMITMENT_SCHEDULE
)

export const destroyVirtualFields = (form, { key }) =>
  createAction(CONSTANTS.DESTROY_VIRTUAL_FIELDS, { key }, form)

export const handleRebateInfoInteraction = createAsyncFormAction(
  CONSTANTS.HANDLE_REBATE_INFO_INTERACTION
)

export const handleRecurringOrderInteraction = createAsyncFormAction(
  CONSTANTS.HANDLE_RECURRING_ORDER_INTERACTION
)

export const handleSwapCommittedQuantitiesInteraction = createAsyncFormAction(
  CONSTANTS.HANDLE_SWAP_COMMITTED_QUANTITIES_INTERACTION
)

export const addComponentsRow = (
  form,
  {
    parentLineNumber,
    rowId,
    gridApi,
    componentsGridExpanded = true,
    isExternal = false
  }
) =>
  createAction(
    CONSTANTS.ADD_COMPONENTS_ROW,
    { parentLineNumber, rowId, gridApi, componentsGridExpanded, isExternal },
    form
  )

export const openRebateCostTab = createAsyncFormAction(
  CONSTANTS.OPEN_REBATE_COST_TAB
)

export const exitScreenFromModal = (form, { modalId }) =>
  createAction(CONSTANTS.EXIT_SCREEN_FROM_MODAL, { modalId }, form)

export const createCustomAssembly = createAsyncFormAction(
  CONSTANTS.CREATE_CUSTOM_ASSEMBLY
)

export const clearComponentsRow = (
  form,
  {
    parentLineNumber,
    rowId,
    gridApi,
    componentsGridExpanded = true,
    isExternal = false
  }
) =>
  createAction(
    CONSTANTS.CLEAR_COMPONENTS_ROW,
    { parentLineNumber, rowId, gridApi, componentsGridExpanded, isExternal },
    form
  )

export const handleShipperHQInteraction = createAsyncFormAction(
  CONSTANTS.HANDLE_SHIPPER_HQ_INTERACTION
)
