/* eslint react/no-did-update-set-state: 0 */
import React, { useEffect } from 'react'
import {
  KeyboardDatePicker as MuiDP,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/dayjs'
import dayjs from 'dayjs'
import useState from 'hooks/useStateWithCallback'
import usePrevious from 'hooks/usePrevious'
import PropTypes from 'prop-types'
import { isValidDate } from './utils'

const DatePicker = props => {
  const {
    disabled,
    label,
    tabIndex,
    value,
    initialValue,
    disableFuture,
    maxDate,
    setField,
    onBlur,
    inputRef,
    onFocus
  } = props

  const [state, setState] = useState({
    value: initialValue || value || null,
    isDirty: false
  })

  useEffect(() => {
    if (!state.dirty) {
      setState(prev => ({ ...prev, value: value ? dayjs(value) : null }))
    }
  }, [value])

  const onChange = date => {
    setState(prevState => ({ ...prevState, value: date, isDirty: true }))
  }

  const onAccept = date => {
    setState(
      prevState => ({ ...prevState, value: date, isDirty: false }),
      () => {
        setField(date.format('MM/DD/YYYY'))
      }
    )
  }

  const onBlurLocal = e => {
    e.persist()

    if (isValidDate(e.target.value)) {
      setState(
        prev => ({ ...prev, isDirty: false }),
        () => {
          if (e.target.value === state.value.format('MM/DD/YYYY')) {
            setField(state.value.format('MM/DD/YYYY'))
          } else {
            onBlur(e.target.value)
          }
        }
      )
    } else if (state.isDirty) {
      setState(prev => ({ ...prev, value: null }), () => onBlur(''))
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div
        className="ddi-picker-with-label"
        style={{ position: 'relative' }}
        tabIndex={tabIndex}
      >
        <MuiDP
          value={state.value}
          disabled={disabled}
          label={label}
          InputAdornmentProps={{ position: 'end' }}
          autoOk
          onChange={onChange}
          onAccept={onAccept}
          format={props.format}
          variant="inline"
          disableFuture={disableFuture}
          maxDate={maxDate ? dayjs(maxDate) : undefined}
          onBlur={onBlurLocal}
          onDoubleClick={props.onDoubleClick}
          inputRef={inputRef}
          onFocus={onFocus}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}

DatePicker.defaultProps = {
  format: 'MM/DD/YYYY',
}
DatePicker.propTypes = {
  onFocus: PropTypes.func.isRequired,
  format: PropTypes.string,
  inputRef: PropTypes.any.isRequired
}

export default DatePicker
