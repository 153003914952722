import React from 'react'
import { DDIDatePicker } from 'ddiForm/wrapped'
import MobileErrorMessage from 'mobile/components/MobileErrorMessage'
import { useTouchedFields } from 'mobile/pages/OrderPad/utils'
import PropTypes from 'prop-types'

export const CustomDateField = ({editedValue, description,  required, ...props}) => {
  const { handleFocus, touchedField } = useTouchedFields();
  const validationFlag = required && !editedValue && touchedField;
  return (
    <div style={{marginRight: validationFlag ? 20:0}}>
      <DDIDatePicker
      {...props}
      inputStyle={{ marginTop: 5 }}
      initialValue={props.value}
      onFocus={()=>{handleFocus(editedValue)}}
      views={['date']}
      />
      {
      validationFlag ? <MobileErrorMessage title={`${description} is required`}/>
        : null
      }
    </div>
  )
}
CustomDateField.propTypes = {
  editedValue: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool
}
export default CustomDateField
